export const useSegmentStore = defineStore('segment', {
  state: () => ({
    segments: [],
  }),
  actions: {
    async fetchSegments() {
      const supabase = useSupabaseClient()
      const { data } = await supabase.from('segments').select('id, key')
      console.log('store:segment: fetchSegments: data: ', data.length)
      if (data) {
        this.segments = data
      }
    },
    getSegmentByKey(key) {
      const segment = this.segments.find((segment) => segment.key === key)
      if (segment) {
        return segment
      } else {
        return null
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSegmentStore, import.meta.hot))
}
